module.exports = {
  home: 'Home',
	email: 'Email',
  imprint: 'Imprint',
  termsOfService: 'Terms of service',
	whatWeOffer: 'What we offer',
  integration: 'Integration',
	api: 'API',
	ourProducts: 'Products',
	tyvisoRecommends: 'Recommends',
	tyvisoRewards: 'Rewards',
	tyvisoGo: 'Go',
	tyvisoGWP: 'Gift With Purchase',
	tyvisoGAP: 'Gift After Purchase',
	yourGoals: 'Your Goals',
	acquireNewCustomers: 'Acquire New Customers (CPA)',
	raiseCustomerLifetime: 'Raise Customer Lifetime Value (LTV)',
	monetiseYourEcommerce: 'Monetise Your Ecommerce Site (Revshare)',
	upliftBasketCompletion: 'Uplift Basket Completion (CRO)',
	integrationGuide: 'Recommends/Go Setup Guide',
	gapGuide: 'Gift After Purchase Setup Guide',
	rewardsSetuGuide: 'Rewards Setup Guide',
	gwpSetuGuide: 'Gift With Purchase Setup Guide',
	eGuides: 'eGuides',
	aboutUs: 'About us',
	contact: 'Contact',
	logIn: 'Login',
	ourPartners: 'Our Partners',
	ourStory: 'Our Story',
	adSpecs: 'Ad Specs',
	learn: 'Learn',
	tyvisoStudio: 'Tyviso Studio', 
	caseStudies: 'Case studies',
	successStories: 'Success Stories',
	foodCase: 'Food & Beverage',
	wildCase: 'Food & Beverage',
	jewelleryCase: 'Jewellery Brand',
	supplementCase: 'Vitamin Supplement',
	pressLinks: 'Press',
	pressLinksDescription:
		'View the latest coverage of Tyviso as we lead the brand-to-brand revolution.',
  pressLinks10: 'Brand partnerships to improve customer retention',
  pressLinks11: 'Exploring The Value Of Gift With Purchase',
  pressLinks12: 'Brand Partnerships Wrapped: 2023',
  pressLinks13: 'Marketers drawn to the affiliate channel in order to access emerging tech, survey finds',
  pressLinks14: 'Brand partnerships raise profitability, CVR, and AOV at Secret Sales',
  pressLinks15: 'Awin shortlisted for eight awards at the 2024 Performance Marketing Awards',
  pressLinks16: 'Best Technology and Telecoms Campaign',
  pressLinks17: 'Key new partners who are driving change',
  pressLinks18: 'Meet Awin\'s 2024 Power 100',
  pressLinks19: 'Affiliate Network or SaaS of the Year at the 2024 UK PMAs',
  pressLinks20: 'Myth-busting the affiliate marketing channel',
  pressLinks21: 'Four ways to boost performance and accelerate growth',
	homepageStageTitle: 'Your all-in-one <strong>brand partnerships</strong> platform',
	homepageStageDescription:
		'Partner with over 5000 leading brands to attract new customers & bolster the value of your existing ones.',
	homepageStagePrimaryCta: 'Get a demo',
	homepageStageSecondaryCta: 'Learn more',
	homepageInfoTitle: 'What we do',
	homepageInfoOneTitle: 'Find shoppers in the moment',
	homepageInfoOneDescription:
		"Tyviso's engine is fueled by real-time purchase data. We put your brand in front of users whilst their credit card is still in their hand and warm",
	homepageInfoTwoTitle: 'Engage with them',
	homepageInfoTwoDescription:
		"Create unique and relevant ad experiences using Tyviso's behaviour data. Show your ads only to people who have made a purchase",
	homepageInfoThreeTitle: 'Drive marketing results',
	homepageInfoThreeDescription:
		'Impress your boss and generate high-value purchases now, not later',
	homepageInfoFourTitle: 'Use Science',
	homepageInfoFourDescription:
		'Recommendation and trust are the worlds biggest superpowers in driving sales. Seriously, just ask the Kardashians',
	homepageInfoFiveTitle: 'Work Quickly',
	homepageInfoFiveDescription:
		"Everything can be set up in minutes, not hours. If it isn't, we'll buy you lunch",
  integrationSubtitle: 'So easy your grandmother could do it',
  integrationContact: 'Any questions? E-mail Us',
  getIntegrated: "Get Integrated Now",
  scalablePartnershipsTitle: 'Brands use Tyviso to craft <strong>highly profitable</strong> and scalable partnerships',
	compTestimonialsTitle: 'Companies that love Tyviso',
	allStars: 'All Stars Include:',
  spOneTitle: 'Align other brands with your purpose',
  spOneP1: 'Tyviso is the brand partnerships platform designed to drive your business forward. </br></br>Gift and reward your shoppers, to get them to buy more from you. All whilst using the superpower of other brands.</br></br>Available both on browser and in-app, you can benefit from Tyviso anytime, wherever your customers are.</br</br>',
  spOneP2: 'Learn More &#8594',
  spTwoTitle: 'How we help your business',
  spTwoP1: 'Trusted by leading brands, Tyviso gives your business the edge by helping you partner with many brands at the same time.</br',
  spTwoP2: 'Save time',
  spTwoP3: 'Easily track and manage your brand partnerships all in one place',
	spTwoP4: 'Eliminate the admin and bureacracy of finding new partners that match your audience',
	spTwoP5: 'Make money',
	spTwoP6: 'Increase LTV, reduce customer acquisition costs and skyrocket your revenue per user',
	spTwoP7: 'Earn an average X return on investment',
  spThreeTitle: 'How your customers benefit',
	spThreeP1: 'We help you cater for every single customer by giving them:',
	spThreeP2: 'Thousands of curated deals and discounts',
	spThreeP3: 'Freedom and flexibility to choose what they want',
	spThreeP4: 'An engaging on-brand experience',
	spThreeP5: 'Extra value for their money',
	spThreeP6: 'Knowledge that you care about them',
  keyBenefitsTitle: '<small>Key Benefits</small></br>Why use Tyviso?',
  kBCardTitleOne: 'One-click setup',
  kBCardDescOne: '<a href="/">Explore the guide</a>',
  kBCardTitleTwo: 'Deep Customisation',
  kBCardDescTwo: 'We design, you approve',
  kBCardTitleThree: 'Co-Management',
  kBCardDescThree: 'Your goals, our support',
  testimonialsSliderTitle: 'Success Story',
  testimonialsSliderOneText: '"Working with Tyviso to launch our new Plusnet Perks platform has really moved the dial on how stakeholders see the channel overall.</br></br>Mutual dedication, attention to detail and excellent relationship building have all ensured we keep the customer at the heart of everything"',
  testimonialsSliderOneOwner: 'Amy Metcalfe</br>Digital Trading Manager, Plusnet',
  testimonialsSliderTwoText: '"We are incredibly pleased with the results of our partnership with Tyviso. Venturing into brand collaborations for the first time, we\'ve met our objectives and enhanced our customer journey.<br /><br />The seamless integration of Tyviso’s innovative Gift With Purchase platform has been a pivotal factor in elevating both customer engagement and satisfaction. The remarkable improvements we\'ve seen in basket completion and average order value reflect our dedication to providing the best shopping experience."',
  testimonialsSliderTwoOwner: 'Toby Gavin</br>CMO, Online4Baby',
  testimonialsSliderThreeText: '"Having rigorously tested the impact of GWP on conversion rate, we were ecstatic to confirm the hypothesis that offering ‘Free Gifts’ to our customers before checkout would increase our basket completion rate.<br /><br />Launching GWP with Tyviso has unlocked a brand new rev-gen channel for the business with huge potential to scale. We’ll now be looking to roll GWP out across all our markets.<br /><br />We have also seen a very positive response from our customers who appreciate the ‘Free Gift’, contributing to an enhanced customer experience when shopping with us."',
  testimonialsSliderThreeOwner: 'Flora Jetha<br/>Senior Partnerships Executive, Wild',
  testimonialsSliderFourText: '<br /><br />"From Day 1 I couldn’t say a better word about Tyviso’s support and flexibility. The team worked with us on finding a solution that fit our site, remained close while we tested, and were a constant support across optimisations and data analysis. <br /><br />I’m looking forward to a continued future with the team, and I’m excited to see what more we can achieve with Tyviso."',
  testimonialsSliderFourOwner: 'Sian Wells<br/>Digital Marketing Manager',
  testimonialsSliderFiveText: '<br /><br />"Working with Tyviso and their proprietary technology to be able to help bring affinity third party offers to our customers has been instrumental in both improving our performance and customer proposition over the last 6 months."<br /><br />',
  testimonialsSliderFiveOwner: '<br />Alex Adamson<br />CMO, Secret Sales<br /><br />',
  brandPartnershipsDescription: 'Promote brand partnerships at unique parts of the shopper journey when customers are hungry for more.',
	learnMore: 'Learn more',
	seeTheDemo: 'See the demo',
	applyToJoin: 'Apply to join',
	learnMoreStageTitle: 'Driving sales from the community',
	learnMoreStageDescription:
		"Communities are made to help each other: Tyviso's diverse community of brands is doing just that",
	learnMoreStagePrimaryCta: 'Apply to join',
	learnMoreStageSecondaryCta: 'See a demo',
	learnMoreSectionOneTitle: 'Show your brand when users want more',
	learnMoreSectionOneDescription:
		'Your product is recommended to high intent purchasers looking to spend',
	learnMoreSectionTwoTitle: 'Data rich recommendations',
	learnMoreSectionTwoDescription:
		'Ensure that your brand reaches interested people by leveraging all of the user data powering the Tyviso engine',
	learnMoreSectionThreeTitle: 'Ultimate transparency',
	learnMoreSectionThreeDescription:
		"Knowing where your shoppers are coming from is important, that's why we share upfront what brands in the community",
	learnMoreSectionFourTitle: 'Easy to get started',
	learnMoreSectionFourDescription:
		'Once approved, it takes under five minutes to start acquiring new customers',
	learnMoreSectionFiveTitle: 'Conquer intent',
	learnMoreSectionFiveDescription:
		'Our community delivers unrivalled conversion rates and <b>you only pay for results</b>',
	recommendsHowItWorksTitle: 'How it works',
	recommendsHowItWorksOne:
		'We put a hyper customisable banner on your thank you page.',
	recommendsHowItWorksTwo:
		'You recommend your customers to a community of non-competing brands.',
	recommendsHowItWorksThree:
		'An entire network of brands in return recommend their customers to you.',
	recommendsHowItWorksFour:
		'Only pay a small commission for successful sales.',
	recommendsTrustItemOne: 'Raise your LTV',
	recommendsTrustItemTwo: 'Aquire new customers',
	recommendsTrustItemThree: 'Monetise your site',
	rewardsHowItWorksTitle: 'How it works',
	rewardsHowItWorksOne: 'Create an empty page on your  website or app',
	rewardsHowItWorksTwo:
		'Paste one <strong>line</strong> of code onto the empty page',
	rewardsHowItWorksThree: 'Log into Tyviso  Rewards and select your partners',
	rewardsHowItWorksFour: 'Win the hearts of of your customers',
	rewardsFeaturesTitleOne: 'Reward your most loyal customers',
	rewardsFeaturesDescOne:
		'You know your customers best. Select rewards for them from over 100 different partners.<br /><br />Rotate them every month to want them to keep coming back for more.',
	rewardsFeaturesTitleTwo: 'Search for the perfect match',
	rewardsFeaturesDescTwo:
		'Take minutes, not hours to find the perfect matches for you. If only dating worked the same.<br /><br />Use a variety of filters, performance ratings and tailored suggestions to get started.',
	rewardsFeaturesTitleThree: 'Your reward page, the way you want it.',
	rewardsFeaturesDescThree:
		'Keep your branding team happy by fully customising how your page looks. Select from a variety of templates, or work with Tyviso studio to get a custom finish to be proud of.',
	rewardsFeaturesTitleFour: 'Available at all touchpoints',
	rewardsFeaturesDescFour:
		'Offer rewards on desktop, mobile web and in your app. Tyviso Rewards is fully responsive to how your customers interact with you.',
	rewardsFeaturesTitleFive: 'Publish any change in 10 seconds',
	rewardsFeaturesDescFive:
		'Want to quickly swap out one reward for another one? No problem, simply log in, make and preview your changes then publish.',
	rewardsFeaturesTitleSix: 'Use your assets to drive volume',
	rewardsFeaturesDescSix:
		'Promote your rewards page on your CRM e-mails, website, social media and even ads to get maximum value.<br /><br />Name drop who you are partnering with to your customers, everyone loves a collab.',
	rewardsFeaturesTitleSeven: 'Provide an offer for others to promote',
	rewardsFeaturesDescSeven:
		'Upload an offer for other brands to apply to offer to their customers. Only pay a commission for a successful sale.',
	cpaFeaturesTitleOne: 'Use recommendations to drive growth',
	cpaFeaturesDescOne:
		'It’s scientifically proven that recommendations are the best way of selling.<br /><br />Have your brand shown to customers at various parts of other brands sites.',
	cpaFeaturesTitleTwo: 'Supply rewards to get customers on board',
	cpaFeaturesDescTwo:
		'Provide a reward that other brands can promote to their customers. Dangle your brand in front of shoppers when they are looking for a dopamine hit.',
	cpaFeaturesTitleThree: 'The ‘Aldi Middle Aisle’ effect',
	cpaFeaturesDescThree:
		'Have you ever walked into Aldi for a loaf of bread, and then walked out with a lawnmower? We know we have.<br /><br />By showing many brands to customers, we help them to complete their shopping list, without them proactively having to go out of their way to do so.',
	cpaTitle: 'Acquire New Customers (CPA)',
	cpaDescription:
		'Drive new customers to your brand using the power of other brands.',
	cpaSideTitle:
		'Drive new customers to your brand using the power of other brands.',
	cpaSideDescription:
		'Tyviso helps join brands together using both our platform. By non-competing brands work together, we deliver industry-leading performance.',
	cpaFeatures: 'How Tyviso delivers sales',
	cpaSimpleSideTitle: 'Acquiring New Customers',
	cpaSimpleSideSubtitle:
		'Acquiring new customers efficiently is now more important than ever.',
	cpaSimpleSideContent:
		'But, it has never been more difficult or expensive.<br /><br />Facebook tracking doesn’t work like it used to, CPM’s have gone up 50% in the last 3 years and competition from other brands has never been more fierce.<br /><br />Buying on Paid Search, Programmatic & Display can be effective. But ultimately is biddable, meaning there is always risk.<br /><br />But what if there was a different way, on a guaranteed rate of return?<br /><br />',
	cpaSimpleSideFooter:
		'That’s where brand partnerships comes in. It provides:',
	cpaSimpleSideInfoTitleOne: 'A fixed return on investment (CPA)',
	cpaSimpleSideInfoDescOne:
		'By working on a fixed commission basis, there’s no guesswork. Instead, it delivers a stable income at a rate of return that you choose.',
	cpaSimpleSideInfoTitleTwo: 'Free brand building',
	cpaSimpleSideInfoDescTwo:
		'Social media platforms full of cat videos and text based ads not floating your brand-building boat? We suspect not. Brands are more trustworthy, and therefore so is their recommendation of you.',
	cpaSimpleSideInfoTitleThree: 'Zero risk',
	cpaSimpleSideInfoDescThree:
		'There is no setup, monthly fees or bidding. Simply a two-minute setup, followed by a whole lot of upside. What’s not to love?',
	cpaSimpleSideInfoTitleFour: 'Above-average order values (AOV)',
	cpaSimpleSideInfoDescFour:
		'In Q2 2022, Tyviso delivered an AOV from it’s traffic 6% higher than our customer’s average. Great for margins, great for business.',
	cpaSimpleSideInfoTitleFive: 'High conversion rates',
	cpaSimpleSideInfoDescFive:
		'We deliver a network conversion rate of almost 8%. One client even had a conversion rate of 23%, which can be found in our case studies',
	cpaBrandToBrand: 'Brand partnerships are here to stay',
	cpaSoWhat: 'So, what are you waiting for?',
	croTitle: 'Uplift Basket Completion (CRO)',
	croSideTitle:
		'Brand partnerships positively influence the most critical page on your site.',
	croDescription:
		'Brand partnerships positively influence the most critical page on your site.',
	croSideDescription:
		'Promote other brands during checkout and sell more of your own products too.',
	croFeatures: 'How Tyviso Helps CRO',
	croFeaturesTitleOne: 'Partner gifts raise perceived value.',
	croFeaturesDescOne:
		"What used to be a £50 purchase, is now that plus another product with your compliments..<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	croFeaturesTitleTwo: 'Every experience is deeply customised.',
	croFeaturesDescTwo:
		"One size does not fit all. We painstakingly work with your brand team to create a seamlessly blended experience..<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	croFeaturesTitleThree: 'Experience lightning speed.',
	croFeaturesDescThree:
		"All of our products use the latest technology to load in under 0.3 seconds. No one likes waiting around, why should your customers..<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",

	goTitle: 'Tyviso Go',
	goDescription:
		'Benefit from the support of 100+ SMB brands who recommend each other.',
	goSideTitle:
		'Benefit from the support of 100+ SMB brands who recommend each other.',
	goSideDescription:
		'Showcase your brand to shoppers who love supporting small businesses. Only pay for sales and earn a minimum of £6.67 per £1 spent.<br /><br />Available for brands that do less than 5,000 transactions a month & on Awin Access.',
	goCompanies: 'Hot brands of tomorrow include:',
	goHowItWorksTitle: 'How it works',
	goHowItWorksOne: 'Select a banner to put on your thank you page.',
	goHowItWorksTwo: 'Recommend your customers to other non-competing SMBs',
	goHowItWorksThree: 'Other SMBs in turn recommend their customers to you',
	goHowItWorksFour: 'Only pay a small commission for  successful sales.',
	goFeaturesTitleOne: 'Team up with brands just like you',
	goFeaturesDescOne:
		'Being a small business is challenging, by joining forces, benefit from the hard work that each of you is putting in. Shoppers love supporting small businesses, we’ll put you in front of the engaged ones.',
	goFeaturesTitleTwo: 'Integrate in seconds with the Awin Mastertag',
	goFeaturesDescTwo:
		'Pick a template, then leave the hard work to us and Awin. You don’t need to be a tech wizard to get going - we’ll guide you every step of the way.',
	goFeaturesTitleThree:
		'Upload an ad once, then get back to running your business',
	goFeaturesDescThree:
		'Tyviso is the ultimate plug and play solution. Upload one ad once, and watch the sales come in whilst you do the stuff important to you.',
	goFeaturesTitleFour: 'Build brand gravitas for free',
	goFeaturesDescFour:
		'Recommendation is the most powerful tool in selling. Tyviso Go is charged on sale only, so everything before that is a free recommendation. Nice, aren’t we?',
	gwpTitle: 'Tyviso Gift With Purchase <br />',
	gwpDescription:
		'Provide a gift with purchase, and watch your profit margin and brand loyalty rise.',
	gwpSideTitle: 'Raise your basket completion by 7%',
	gwpSideDescription:
		'Provide a gift with purchase, and watch your profit margin and brand loyalty rise.',
	gwpSideListItemOne: 'Sky-rocket site revenue',
	gwpSideListItemTwo: 'Earn needle-moving commission',
	gwpSideListItemThree: 'Launch in under 1 hour',
	gwpHowItWorksTitle: 'How it works',
	gwpHowItWorksOne: 'Place script on your site',
	gwpHowItWorksTwo: 'Log in and select your partners',
	gwpHowItWorksThree: 'Add value to every checkout',
	gwpQuoteTitle: 'How much could I earn?',
	gwpQuoteDesc:
		'Enter your monthly number of site transactions and your e-mail below and receive your potential paycheque amount',
	gwpQuoteCta: 'Hit me with it',
	gwpQuoteThankYouTitle: 'Thank you for getting in touch!',
	gwpQuoteThankYouDesc:
		'One of our colleagues will get back in touch with you soon!',
	gwpFeaturesTitleOne: 'Customise your look',
	gwpFeaturesDescOne:
		'It’s your page, so your rules. Work with Tyviso to design the ideal gift widget on all devices.',
	gwpFeaturesTitleTwo: 'Select your gift from a wide range of partners',
	gwpFeaturesDescTwo:
		'Tyviso has access to 5,000+ different brands. We collect all the assets, so you don’t need to lift a finger.',
	gwpFeaturesTitleThree: 'Choose your rates',
	gwpFeaturesDescThree:
		'Manage your yield - set a floor of what you want to be paid for a feature in your basket.',
	gwpFeaturesTitleFour: 'Rotate for performance',
	gwpFeaturesDescFour:
		'Add different weights to different ads, and optimise until you find the perfect gift for your audience.',
	gwpFeaturesTitleFive: 'Change Instantly',
	gwpFeaturesDescFive:
		'Secured a new kick a** offer you want to run immediately? Login and change it in 10 seconds.',
  gwpProductHeaderTitle: '<strong>Gift</strong> shoppers during checkout to increase your site performance',
  gwpProductHeaderDesc: 'Enjoy a raised <strong>basket completion</strong> rate and <strong>average order value</strong> by using other brands, not discounting yours',
	gwpProductBenefitsTitle: 'What are the benefits?',
  gwpProductHowItWorksTitle: '<strong>How it works |</strong> 3 simple steps to maximise the value of your checkout',
  
  rewardsProductHeaderTitle: '<strong>Reward</strong> existing shoppers to transform their loyalty',
  rewardsProductHeaderDesc: 'Enjoy a raised <strong>life time value</strong> and <strong>reduced churn</strong> by offering perks supplied by other brands',
	rewardsProductBenefitsTitle: 'What are the benefits?',
  rewardsProductHowItWorksTitle: '<strong>How it works |</strong> 3 simple steps to maximise the value of your customers',

  gapProductHeaderTitle: '<strong>Present</strong> shoppers after checkout with a thank you gift',
  gapProductHeaderDesc: 'Enjoy a raised <strong>customer satisfaction</strong> and <strong>repeat order rate</strong> by using other brands, not discounting yours  ',
	gapProductBenefitsTitle: 'What are the benefits?',
  gapProductHowItWorksTitle: '<strong>How it works |</strong> 3 simple steps to maximise the value of your post-checkout',

  gapTitle: 'Tyviso Gift After Purchase <br />',
	gapDescription:
		'Provide a gift with purchase, and watch your profit margin and brand loyalty rise.',
	gapSideTitle: 'Earn revenue after a shopper has completed checkout',
	gapSideDescription: 'Showcase up to 9 partner offers',
	gapSideListItemOne: 'Earn up to £2 per click',
	gapSideListItemTwo: 'Fully customise appearance',
	gapSideListItemThree: 'Launch in under 5 minutes',
	gapHowItWorksTitle: 'How it works',
	gapHowItWorksOne: 'Place script on thank you page',
	gapHowItWorksTwo: 'Log in and select your partners',
	gapHowItWorksThree: 'Earn revenue when shoppers claim',
	gapQuoteTitle: 'How much could I earn?',
	gapQuoteDesc:
		'Enter your monthly number of site transactions and your e-mail below and receive your potential paycheque amount',
	gapQuoteCta: 'Hit me with it',
	gapQuoteThankYouTitle: 'Thank you for getting in touch!',
	gapQuoteThankYouDesc:
		'One of our colleagues will get back in touch with you soon!',
	gapFeaturesTitleOne: 'Customise your look',
	gapFeaturesDescOne:
		'It’s your page, so your rules. Use our intuitive UI to design your grid in minutes on all devices.',
	gapFeaturesTitleTwo: 'Select your gift from a wide range of partners',
	gapFeaturesDescTwo:
		'Tyviso has access to 5,000+ different brands. We collect all the assets, you place them on the grid.',
	noOfTransactions: 'No. of Transactions',
	ourMission: 'Our mission',
	ourMissionDescription:
		"To build the world's best product recommendation engine",
	ourValues: 'Our values',
	ourValuesDescription:
		'A mission is one thing, our values are critical to how we operate internally and externally, particularly within a community context',
	ourValuesItemOne: "Collaborate, don't compete",
	ourValuesItemTwo: 'Problems are constructive challenges',
	ourValuesItemThree: 'Feedback is a gift',
	ourValuesItemFour: 'Give everyone a voice',
	ourValuesItemFive: 'We grow together',
	ourOffices: 'Our offices',
	ourOfficesItemOne: 'London',
	ourOfficesItemTwo: 'Malaga',
	ourOfficesItemThree: 'Bucharest',
	ourTeam: 'Our management team',
	ourTeamItemOne: 'Pierre Cozzani',
	ourTeamItemOneDescription: 'Founder & CEO',
	ourTeamItemTwo: 'Adrian Vella',
	ourTeamItemTwoDescription: 'Co-founder & COO',
	ourTeamItemThree: 'Alin Inayeh',
	ourTeamItemThreeDescription: 'Co-founder & CTO',
	offersCategories: 'Categories',
	allCategories: 'All categories',
	seeTheOffer: 'Claim now',
	popularOffers: "Here's what's <strong>trending</strong> today",
	allOffers: 'Popular offers',
	searchResultsFor: 'Search results for',
	noSearchResults: 'No results found',
	backToAllOffers: 'Back to all offers',
	cookieConsentTitle: 'This website uses cookies',
	cookieConsentDescription: `We use cookies, including third-party cookies, to improve your experience. To find out more, read our <a href="/privacy-policy">Privacy Statement</a>.`,
	cookieConsentAccept: 'I understand',
	worksWith: 'Ecommerce Platform Partners',
	andMore: '+ More',
	privacyPolicy: 'Privacy Policy',
	inCollaborationWith: 'in collaboration with',
	contactUs: 'Contact us',
	contactUsDescription:
		"If you have a question about our service or would like to contact someone in the Tyviso team please use the form below",
	contactUsEmail: 'Send us an email',
	contactUsChat: 'Start a chat',
	contactUsDemo: 'Book a demo',
	watchDemo: 'Watch the demo',
	closeDemo: 'Close',
	loaderMessage:
		'Loading Your Personalised Offers<br /><strong>Hold on tight...</strong>',
	whyTyvisoTitle: 'Why Tyviso',
	whyTyvisoDescription:
		"Don't just take our word for it, see how we stack up against the competition",
	averageRoi: 'Average ROI',
	averageEcommerceCvr: 'Average E-commerce CVR',
	averageAdCtr: 'Average Ad CTR',
	ecomPlatformSupport: 'E-Com Platform Support',
	machineLearning: 'Machine Learning',
	tracking: 'Tracking',
	allDeviceSupport: 'All Device Support',
	paymentOnPerformance: 'Payment on Performance',
	brandSafe: 'Brand Safe / PII Secure',
	sameSessionAttribution: 'Same-Session Only Attribution',
	freeLoyaltyTool: 'Free Customer Loyalty Tool',
	noOptimisations: 'No Ongoing Optimisations',
	faqTitle: 'Frequently asked questions',
	faqOneTitle: 'What does Tyviso do?',
	faqOneDescription:
		"We drive new customers to your website after they've bought something from another partner in our community.",
	faqTwoTitle: 'What kind of ROI can I expect?',
	faqTwoDescription:
		'We guarantee over a 600% return. Seriously, tell your boss.',
	faqThreeTitle: 'How am I charged for new customers?',
	faqThreeDescription:
		'If you sell a subscription, we decide on a flat fee together. If not, we charge a small % fee of the basket value.',
	faqFourTitle: 'What is the definition of a new customer?',
	faqFourDescription:
		'A new customer is an incremental sale we have driven you, referred from another community member.',
	faqFiveTitle:
		'Are there any additional charges after a customer acquisition?',
	faqFiveDescription:
		"Nope! Once we've delivered you a new user it's over to you to nurture them and turn them into a repeat customer. We're nice like that.",
	faqSixTitle: 'Are there any monthly or setup fees?',
	faqSixDescription:
		"No, we'd rather want you spent that money on your office Christmas party.",
	faqSevenTitle: 'How am I billed?',
	faqSevenDescription:
		'We send an invoice at the end of the month and a list of sales generated so you can double-check everything.',
	faqEightTitle: 'How do you decide the order of ads on your offer page?',
	faqEightDescription:
		'We use machine learning and a number of optimisation levers (thanks Robots!) - every user has a completely different experience. The more relevant your store for that user, the higher your ranking will be.',
	faqNineTitle: 'We dont use an ecommerce platform listed on your site.',
	faqNineDescription:
		"No problem, we probably couldn't just find their logo. We've got you covered.",
	faqTenTitle: 'Is the Tyviso brand safe?',
	faqTenDescription:
		'Absolutely, all ads are shown within the Tyviso engine. Surrounding content is purely just other partners.',
	faqElevenTitle:
		'Do you collect any personal identifyable information (PII)?',
	faqElevenDescription:
		'No, we are completely clean from a GDPR perspective. Nobody wants beef with the regulator.',
	faqTwelveTitle: 'Do I need a developer to implement your code?',
	faqTwelveDescription: `In most cases, our code can be pasted into an existing box on your ecommerce platform. For everything else we can help for free. <a href="/setup-guide">See our setup guides</a>`,
	faqThirteenTitle:
		"Is it possible to sell our goods on others' 'Thank You' page without then offering sales on ours?",
	faqThirteenDescription:
		'No, all community members must have the widget. This widget is fully customisable in terms of call to action and physical appearance to make sure you love it.',
	faqFourteenTitle: 'Am I stuck in a long legal contract?',
	faqFourteenDescription:
		'All we ask is a month notice should you wish to leave our community, no questions asked.',
	faqFifteenTitle: 'Is your best practice content free?',
	faqFifteenDescription: 'Yes - sharing is caring!',
	faqSixteenTitle: 'Can I show against my competitors?',
	faqSixteenDescription:
		"No, we have clever automatic measures in place to make sure this doesn't happen.",
	faqSeventeenTitle: 'Are ads from other partners shown directly on my site?',
	faqSeventeenDescription:
		'No, a user goes from a partner website to our engine hosted by us. Then, they are shown all of our communities offers.',
	faqShortTitle: 'FAQ',
	faqSocialProofOneTitle: 'Experience',
	faqSocialProofOneDescription: 'A rich history with world class advertisers',
	faqSocialProofTwoTitle: 'Zero Risk',
	faqSocialProofTwoDescription: 'Payment on results only',
	faqSocialProofThreeTitle: 'Incrementality',
	faqSocialProofThreeDescription:
		'Acquire customers like you never have before',
	faqSocialProofFourTitle: 'Proven Results',
	faqSocialProofFourDescription: '100% customer satisfaction rate',
	integrationGuideTitle: 'Setup Guide',
	rewardsGuideTitle: 'Rewards Setup Guide',
	gapGuideTitle: 'Gift After Purchase Setup Guide',
	gwpGuideTitle: 'Gift With Purchase Setup Guide',
	gtmDocsTitle: 'GTM Documentation',
  gtmDocsDescription: 'GTM Documentation',
	integrationGuideDescription: 'How to Get Started',
	integrationGuideQuestions: 'Any questions?',
	emailUs: 'Email us',
	stepOne: 'Step 1',
	stepTwo: 'Step 2',
	stepThree: 'Step 3',
	stepFour: 'Step 4',
	stepFive: 'Step 5',
	integrationGuideStepOneTitle: 'Place banner on your thank-you page',
	integrationGuideStepOneDescription:
		"So simple Grandma can do it. The Tyviso banner takes less than 2 minutes to place and doesn't require a developer",
	integrationGuideStepOneLinkOne: 'eCom platform guides',
	integrationGuideStepOneLinkTwo: 'Google Tag Manager',
	integrationGuideStepTwoTitle: 'Provide an offer for prospective new clients',
	integrationGuideStepTwoDescription:
		'Just provide a couple of images, some ad copy and a tracking URL',
	integrationGuideStepTwoLinkOne: 'Tyviso ad specs',
	integrationGuideStepTwoLinkTwo: 'Request a mockup',
	integrationGuideStepThreeTitle: 'Acquire new customers',
	integrationGuideStepThreeDescription:
		"It's as simple as that<br><br>Work with an affiliate network listed below? Great! We're friends with them too",
	integrationGuideFaqOneTitle: 'Do I need a developer?',
	integrationGuideFaqOneDescription: `Give him the day off.  We have native integrations with many popular eCom platforms. Our guides to set up via tag managers can also be found here. <a href="https://drive.google.com/drive/u/1/folders/1oxZiQY_m5tee5eOMvCth3RMyQmzfMZyU">here</a>`,
	integrationGuideFaqTwoTitle: 'How long does it take to setup?',
	integrationGuideFaqTwoDescription:
		'Less than the time it takes to make a cup of tea',
	integrationGuideFaqFourTitle: "I don't use an eCom platform in your guides",
	integrationGuideFaqFourDescription: `No problem, we work with all platforms. We just haven't got around to writing that guide yet. <a href="/contact">Contact Us</a>, and we'll get right on it`,
	integrationGuideFaqFiveTitle: 'Will your banner slow my checkout?',
	integrationGuideFaqFiveDescription:
		"No. Our banner only loads after purchase, so it will not slow down your checkout. It's also super light at less than 1kb big",
	integrationGuideFaqSixTitle: 'Do you collect any PII?',
	integrationGuideFaqSixDescription:
		'No, that would be a headache for both your legal department and ours 😀',
	integrationHostGuideFaqOneTitle: 'Do I need a developer?',
	integrationHostGuideFaqOneDescription:
		'Give him the day off. You can copy and paste our script straight on to your page or app, or use a tag manager.',
	integrationHostGuideFaqTwoTitle:
		'Will your Reward Portal slow my whole site?',
	integrationHostGuideFaqTwoDescription:
		"No. Our banner only loads on one page of your site/App. It's also super light at less than 1kb big",
	integrationSupplierGuideFaqOneTitle: 'How many ads do I need to supply?',
	integrationSupplierGuideFaqOneDescription:
		'Just the one. You can also make exclusive offers to our Reward Hosts',
	integrationSupplierGuideFaqTwoTitle: 'What are the ad Specs?',
	integrationSupplierGuideFaqTwoDescription: `These can be found here: <a href="/ad-specs">https://tyviso.com/ad-specs</a>. In the platform you're guided on everything during setup.`,
	integrationSupplierGuideFaqThreeTitle:
		'How long does it take to get my ad approved?',
	integrationSupplierGuideFaqThreeDescription: 'Typically less than 24 hours.',
	integrationSupplierGuideFaqFourTitle: 'Can I edit my ad?',
	integrationSupplierGuideFaqFourDescription: `Yes, simply edit and submit. It'll then go back into approval status.`,
	gwpHostFaqOneTitle: 'Do I need a developer?',
	gwpHostFaqOneDescription:
		'Give him the day off. You can use a tag manager or if you need to hard code, we can help.',
	gwpHostFaqTwoTitle: 'How long does it take to setup?',
	gwpHostFaqTwoDescription:
		'Maximum 1 hour. You can see our setup guide <a href="https://docs.google.com/document/d/1tgSjTFU5ZWLQogFieb3LPN0A5kgPJhAXn6nAckjKcw0/edit">here</a>.',
	gwpHostFaqThreeTitle: 'Will Gift With Purchase slow down my basket?',
	gwpHostFaqThreeDescription:
		'No, it typically loads in under 0.3 seconds at the same time as the page.',
	gwpHostFaqFourTitle: 'Do you collect any PII?',
	gwpHostFaqFourDescription:
		'No, that would be a headache for both your legal department and ours 😀',
	rewardsGuideHostStepOneTitle: 'Login to Tyviso Rewards.',
	rewardsGuideHostStepOneDescription: 'Get a login from your Tyviso Rep.',
	rewardsGuideHostStepOneLinkOne: 'View Video',
	rewardsGuideHostStepOneLinkTwo: 'Book Demo',
	rewardsGuideHostStepTwoTitle: 'Customise Your Look',
	rewardsGuideHostStepTwoDescription:
		'Select from a variety of templates and add your own individual touches.',
	rewardsGuideHostStepThreeTitle: 'Choose your partners',
	rewardsGuideHostStepThreeDescription:
		'Pick from a library of leading advertisers who you want to work with',
	rewardsGuideHostStepFourTitle: 'Place Your Script',
	rewardsGuideHostStepFourDescription:
		'Create an empty page on your site or mobile app. Copy and Paste. Go Live.',
	rewardsGuideHostStepFourEmptyDescription: ' ',
	rewardsGuideSupplyStepTwoTitle: 'Upload an Ad',
	rewardsGuideSupplyStepTwoDescription:
		'Submit a reward for brands to promote to their audience to the ad library.',
	rewardsGuideSupplyStepThreeTitle: 'Acquire New Customers',
	rewardsGuideSupplyStepThreeDescription:
		'It’s as simple as that. We only wrote this bit to make the page look symmetrical.',
	gwpGuideHostStepOneTitle: 'Login to Tyviso Gift With Purchase.',
	gwpGuideHostStepOneDescription: 'Get a login from your Tyviso Rep.',
	gwpGuideHostStepOneLinkOne: 'View Video',
	gwpGuideHostStepOneLinkTwo: 'Book Demo',
	gwpGuideHostStepTwoTitle: 'Customise Your Look',
	gwpGuideHostStepTwoDescription:
		'Select from a variety of templates and add your own individual touches.',
	gwpGuideHostStepThreeTitle: 'Choose your partners',
	gwpGuideHostStepThreeDescription:
		'Pick from a library of leading advertisers who you want to work with',
	gwpGuideHostStepFourTitle: 'Connect to your CRM',
	gwpGuideHostStepFourDescription:
		'We have guides aplenty to help you get setup quick.',
	gwpGuideHostStepFiveTitle: 'Place Your Script',
	gwpGuideHostStepFiveDescription:
		'Add two scripts to your site in less than a few minutes. Go Live.',
	gwpGuideSupplyStepTwoTitle: 'Upload an Ad',
	gwpGuideSupplyStepTwoDescription:
		'Submit a gift for brands to promote to their audience to the ad library.',
	gwpGuideSupplyStepThreeTitle: 'Acquire New Customers',
	gwpGuideSupplyStepThreeDescription:
		'It’s as simple as that. We only wrote this bit to make the page look symmetrical.',
  requestDemo: 'Request a demo &#8594',
  requestADemo: 'Request a demo',
  requestDemoQTour: 'Have a quick 20 minute tour',
  requestDemoDesc: 'A quick run-through of how Tyviso could work for your brand and time to answer any questions about our proposition',
	getStartedTitle: 'Get Started',
	getStartedDescription:
		'Start running <strong>brand partnerships</strong> that benefit you and your customers <strong class="underline">today</strong>',
	getStartedTrustItemOne: 'No monthly fees',
	getStartedTrustItemTwo: 'No setup fees',
	getStartedTrustItemThree: 'Activate in one click',
  featuredHomeOne: 'Partner with 5000+ brands',
  featuredHomeTwo: 'Optimise your CRO',
  featuredHomeThree: 'Reduce your churn',
  featuredHomeFour: 'Acquire new customers',
	caseStudiesSubtitle:
		"Read the stories behind Tyviso's instant impact on sales, revenue and average order value for leading brands.",
	caseStudiesCardOneTitle: 'Vitamin Supplement Brand',
	caseStudiesCardOneDescription: 'CVR vs Network Program Average',
	caseStudiesCardTwoTitle: 'Food & Beverage Brand',
	caseStudiesCardTwoDescription: 'CVR vs Website Average',
	caseStudiesCardThreeTitle: 'Jewellery Brand',
	caseStudiesCardThreeDescription: 'AOV vs Paid Search',
	caseStudiesCardFourTitle: 'Wild Natural Deodorant',
	caseStudiesCardFourDescription: 'New income from a Gift With Purchase',
	caseStudiesCardFiveTitle: 'Plusnet',
	caseStudiesCardFiveDescription: 'Extra profit and retention from Rewards',
	caseStudiesCardSixTitle: 'Online4Baby',
	caseStudiesCardSixDescription: 'Greater value from a Gift With Purchase',
  caseStudiesCardSevenTitle: 'Parcel2Go',
  caseStudiesCardSevenDescription: 'Customer optimisation from Gift With Purchase',
  caseStudiesCardEightTitle: 'Secret Sales',
  caseStudiesCardEightDescription: 'Total performance from Gift With Purchase',
  caseStudiesCardNineTitle: 'Kitchen Appliance Brand',
  caseStudiesCardNinetDescription: 'Vs when a shopper didn\'t select a gift',
  caseStudiesCardTenTitle: 'Cleaning Brand',
  caseStudiesCardTenDescription: 'With dynamic ads vs non-dynamic',
	supplementCaseTitle: 'Vitamin Supplement Case',
	supplementDescription:
		'Our Brand Partners Recommend Your Brand To Their Customers',
	foodCaseTitle: 'Food & Beverage Case',
	foodCaseDescription: 'Our Brand Partners Recommend Your Brand To Their Customers',
    online4babyCaseTitle: 'Online4Baby takes its first steps',
    online4babyCaseSubTitle: 'Online4Baby is the UK No.1 Independent Nursery retailer.',
    online4babyCaseSimpleSubTitle: 'Online4Baby is the UK No.1 Independent Nursery retailer.',
    online4babyCaseFeaturedTextOne: 'Giving support for bump to baby since 1987 and selling an unrivalled product range, they provide only the best quality baby products at the best prices.',
    online4babyCaseObjectiveDescription: 'As a first foray into brand partnerships, O4B chose Tyviso to help in three critical areas:',
    online4babyCaseObjectiveItemOne: 'Increase basket completion rate',
    online4babyCaseObjectiveItemTwo: 'Raise total site AOV',
    online4babyCaseObjectiveItemThree: 'Surface other brands popular with its shoppers',
    online4babyCaseApproachItemOne: 'O4B used Tyvio’s Gift With Purchase platform to analyse and review relevant partners for the brand, making use of an existing library of high-quality partners.',
    online4babyCaseApproachItemTwo: 'Alongside this, the Tyviso team custom designed and created a highly customised 3-brand widget for the brands’ basket page.',
    online4babyCaseApproachItemThree: 'Deployed through Tag Manager, once the widget was ready – implementation was seamless.',
    online4babyCaseResultsItemOne: 'After 30 days, the results were absolutely off the (baby) scales in terms of return, with highlights including:',
    online4babyCaseResultsItemFour: 'Finally, with a CVR of 12%, the campaign successfully delivered offers that are most relevant to O4B customers, and added value to their experience with the brand.',
    online4babyCaseObjectiveTitle: 'Objectives',
    online4babyCaseResultsTitle: 'Results',
    online4babyCaseApproachTitle: 'Approach',

    
    parcelToGoCaseTitle: 'Special Delivery: Increased Revenue to Parcel2Go',
    parcelToGoCaseSubTitle: 'Parcel2Go is the UK\'s leading courier comparison website.',
    parcelToGoCaseSimpleSubTitle: 'Parcel2Go is the UK\'s leading courier comparison website.',
    parcelToGoCaseFeaturedTextOne: 'With over 20 years of experience, they are proud to offer a range of parcel delivery services from all the biggest carriers to help you get your parcel to its destination at a price you’ll love! P2G has helped find the cheapest prices for over 100 million parcels since 2001.',
    parcelToGoCaseObjectiveDescription: 'Parcel2Go started its journey in brand partnerships to increase its profitability and enhance its customers\' value. With this, the following goals were chosen:',
    parcelToGoCaseObjectiveItemOne: 'Increase Basket Completion Rate',
    parcelToGoCaseObjectiveItemTwo: 'Boost Average Order Value',
    parcelToGoCaseObjectiveItemThree: 'Generate more mutual benefit for both customer and P2G',
    parcelToGoCaseApproachItemOne: 'Parcel2Go used Tyviso\'s Gift With Purchase product by placing it on the basket page. Tyviso helped choose suitable partners for the brand from a ready-made selection of high-quality options.',
    parcelToGoCaseApproachItemTwo: 'Parcel2Go is not a traditional e-commerce site, so extensive testing was performed to find the correct design for their site. After three different split tests, a final design was settled on, which varies by device. ',
    parcelToGoCaseApproachItemThree: 'The GWP script was implemented via Tag Manager, ensuring a seamless and efficient integration.',
    parcelToGoCaseResultsItemOne: 'The final split test delivered the best results. These being:',
    parcelToGoCaseObjectiveTitle: 'Objectives',
    parcelToGoCaseResultsTitle: 'Results',
    parcelToGoCaseApproachTitle: 'Approach',
    
    secretSalesCaseTitle: 'Brand Partnerships: The Secret Sales Tactic',
    secretSalesCaseSubTitle: 'Secret Sales is the leading premium ecommerce marketplace for off-price fashion, sportswear and beauty.',
    secretSalesCaseSimpleSubTitle: '<a target="_blank" rel="nofollow" href="https://www.secretsales.com/">Secret Sales</a>  is the leading premium ecommerce marketplace for off-price fashion, sportswear and beauty.',
    secretSalesCaseFeaturedTextOne: 'Its revolutionary business model is positively disrupting the discount sector, solving the problem of excess inventory for the biggest retailers and brands.',
    secretSalesCaseFeaturedTextTwo: 'Secret Sales features over 2,500 luxury and high street brands, including Dolce & Gabbana, Puma, Kurt Geiger and Versace, and is proud to be home to 12 million registered account holders.',
    secretSalesCaseFeaturedTextThree: 'Secret Sales chose Tyviso to make sure even more customers look sharp.',
    secretSalesCaseObjectiveDescription: 'With a high-converting website already, Secret Sales wanted to explore if they could push their profitability higher with the following goals:',
    secretSalesCaseObjectiveItemOne: 'Increase Basket Completion Rate',
    secretSalesCaseObjectiveItemTwo: 'Raise Average Order Value',
    secretSalesCaseObjectiveItemThree: 'Find offers that resonate well with its audience',
    secretSalesCaseApproachItemOne: 'Secret Sales used Tyviso’s Gift With Purchase product, placing it on the basket page. This meant that their customers could pick a gift from a third-party brand whilst reviewing their basket.',
    secretSalesCaseApproachItemTwo: 'Very conscious of their site design, Secret Sales led the design of their widget, which Tyviso then replicated down to a tee.',
    secretSalesCaseResultsItemOne: 'When measured after 60 days, there was a very clear uplift on the Secret Sales site when users selected a gift on the widget:',
    secretSalesCaseResultsItemFour: 'During the month of December, the above optimisations led to an <strong>11% total site lift.</strong>',
    secretSalesCaseObjectiveTitle: 'Objectives',
    secretSalesCaseResultsTitle: 'Results',
    secretSalesCaseApproachTitle: 'Approach',
    
    ninjaKitchenCaseTitle: 'Recipe for Conquering the Checkout: Worldwide Kitchen Appliance Brand’s Success with Tyviso',
    ninjaKitchenCaseSubTitle: 'How Strategic Partnerships and Tyviso’s Tools Enhanced Checkout Performance',
    ninjaKitchenCaseSimpleSubTitle: 'How Strategic Partnerships and Tyviso’s Tools Enhanced Checkout Performance',
    ninjaKitchenCaseFeaturedTextOne: 'How Strategic Partnerships and Tyviso’s Tools Enhanced Checkout Performance',
    ninjaKitchenCaseObjectiveDescription: 'A globally renowned brand, with substantial site traffic, aimed to boost value for their customers and enhance site performance by focusing on three key components:',
    ninjaKitchenCaseObjectiveItemOne: 'Increase basket completion rate',
    ninjaKitchenCaseObjectiveItemTwo: 'Boost average order value',
    ninjaKitchenCaseObjectiveItemThree: 'Partner with brands that align with their audience and complement their products.',
    ninjaKitchenCaseApproachItemOne: 'The brand used Tyviso\'s Gift With Purchase product by placing it in their basket and checkout page. Tyviso helped the brand select the best-matched FMCG partners and secure best-in-market offers.',
    ninjaKitchenCaseApproachItemTwo: 'Using Tyviso’s A/B testing tool for the initial launch, the GWP widget was initially exposed to 25% of traffic. After monitoring and observing positive results, the brand increased it to 50% and then to 100% traffic a week later.',
    ninjaKitchenCaseApproachItemThree: 'The GWP script was integrated through Awin MasterTag, allowing for a seamless and efficient one-click activation.',
    ninjaKitchenCaseResultsItemOne: 'Within a month, the brand saw a remarkable widget interaction rate, resulting in the following outcomes:',
    ninjaKitchenCaseObjectiveTitle: 'Objectives',
    ninjaKitchenCaseResultsTitle: 'Results',
    ninjaKitchenCaseApproachTitle: 'Approach',
    
    sharkCaseTitle: 'Sparkling Success: How a Leading Cleaning Brand Mopped Up Sales with Gift Offers',
    sharkCaseSubTitle: 'How a Global Leader in Home and Floor Care Enhanced Conversion Rates Using Dynamic Ads and Tyviso’s Gift With Purchase Widget',
    sharkCaseSimpleSubTitle: 'How Strategic Partnerships and Tyviso’s Tools Enhanced Checkout Performance',
    sharkCaseFeaturedTextOne: 'How Strategic Partnerships and Tyviso’s Tools Enhanced Checkout Performance',
    sharkCaseObjectiveDescription: 'A prominent home and floor care brand sought to improve conversion rates and boost average order value in their online channels. With a focus on creating an engaging buying experience, they aimed to turn hesitant shoppers into committed buyers through carefully tailored, high-value offers.',
    sharkCaseApproachItemOne: 'To address these objectives, the brand implemented <strong>Tyviso’s Gift With Purchase</strong> solution. This allowed them to display enticing offers to customers at the most critical point of their purchase journey, the checkout page. By running <strong>dynamic ads</strong>, the brand was able to better target its audience using real-time data, displaying personalised offers that were based on what was in the shopper’s cart. As an example, <strong>customers buying pet vacuum cleaners were offered a free trial on a dog food subscription service.</strong>',
    sharkCaseApproachItemTwo: 'Using Tyviso’s A/B testing tools, dynamic ads</strong> were initially rolled out to a portion of the brand’s online traffic. The test group received dynamic GWP ad placements, while the control group proceeded through the typical purchase flow with static offers. This setup enabled the team to gauge the specific impact of dynamic GWP ads on metrics such as <strong>click-through rate (CTR), checkout conversions, and basket uplift.</strong>',
    sharkCaseApproachItemThree: 'Tyviso worked closely with the brand to integrate the GWP widget, ensuring a frictionless experience that integrated naturally with the company’s existing site layout.',
    sharkCaseResultsItemOne: 'Data from the dynamic ad A/B test has shown overwhelmingly positive results, with performance well above industry averages:',
    sharkCaseObjectiveTitle: 'Objectives',
    sharkCaseResultsTitle: 'Results',
    sharkCaseApproachTitle: 'Approach',

  plusnetCaseTitle: 'Plusnet perks up its rewards offering with Tyviso',
  plusnetCaseSubTitle: 'Plusnet is one of the UK’s largest and most popular broadband providers. Its focus is on simple, budget-friendly packages, shown through the numerous awards and high ratings over the years.\n\nThe BT Group-owned brand chose Tyviso to replace its existing rewards portal in May 2023.',
	plusnetCaseSimpleSubTitle: 'Plusnet is one of the UK’s largest and most popular broadband providers',
  plusnetCaseFeaturedTextOne: 'Its focus on simple, budget-friendly packages has led to numerous awards and high ratings over the years.',
	plusnetCaseFeaturedTextTwo: 'The BT Group-owned brand chose Tyviso to replace its existing rewards portal in May 2023.',
  plusnetCaseObjectiveTitle: 'Objectives',
  plusnetCaseObjectiveDescription: 'Broadband is a fiercely competitive space, and with this in mind, uplift in brand loyalty was chosen as the key KPIs, specifically:',
  plusnetCaseObjectiveItemOne: 'Likeliness to complete an upgrade',
  plusnetCaseObjectiveItemTwo: 'Time & frequency on site',
  plusnetCaseObjectiveItemThree: 'Overall reduction in churn',
  plusnetCaseApproachItemOne: 'Plusnet used Tyviso’s Rewards platform to select from a selection of high-performing existing partners and secure exclusive offers with the help of the Tyviso team.',
  plusnetCaseApproachItemTwo: 'Displaying 12 separate offers in one grid, Plusnet picked offers best suited to their audience demographics and interests.',
  plusnetCaseApproachItemThree: 'The rewards page was designed and blended to be synonymous with the look and feel of the Plusnet site and tone.',
  plusnetCaseResultsItemOne: 'Over five months, the results were unprecedented.\nCustomers that visited the new My Perks page were:',
  plusnetCaseResultsItemTwo: 'And most critically:',
  plusnetCaseResultsItemThree: 'They were 5% less likely to churn than those who didn’t visit My Perks.',
  plusnetCaseResultsItemFour: 'Alongside the above, Plusnet also enjoyed a new revenue stream from the commission partners paid from new customers on their site.',
  wildCaseTitle: 'Wild finds the perfect recipe with SimplyCook & Tyviso.',
	wildCaseSubTitle:
		"Wild has become the UK's #1 sustainable deodorant brand within two years of takeoff.",
	wildCaseDescription:
		"Wild has become the UK's #1 sustainable deodorant brand within two years of takeoff. Benefitting from having an ex-HelloFresh founder on the team, Wild are no stranger to partnerships; it’s a critical growth tool for the Berlin-based dining behemoth.",
	wildCaseFeaturedTextOne:
		'Benefitting from having an ex-HelloFresh founder on the team, Wild are no stranger to partnerships; it’s a critical growth tool for the Berlin-based dining behemoth.',
	wildCaseFeaturedTextTwo:
		"With this in mind, <a target='_blank' href='//www.wearewild.com'>Wild</a> chose Tyviso to help begin its brand partnership journey.",
	wildCaseObjectiveTitle: 'Objectives',
	wildCaseObjectiveDescription:
		'At its core, Wild had three main reasons to promote other brands on its site:',
	wildCaseObjectiveItemOne:
		'To provide extra value to new and existing customers',
	wildCaseObjectiveItemTwo: 'To increase their basket completion rate',
	wildCaseObjectiveItemThree: 'To unlock a new stream of 100% profit revenue',
	wildCaseApproachTitle: 'Approach',
	plusnetCaseApproachTitle: 'Approach',
	wildCaseApproachItemOne:
		'Wild used Tyviso’s, ‘Gift With Purchase’ (GWP) product to filter through a readily available and extensive library of brands to partner with.',
	wildCaseApproachItemTwo:
		'At the end of their search, Wild selected SimplyCook as one of their initial partners.',
	wildCaseApproachItemThree:
		'As part of the partnership, SimplyCook provided a gift of a free trial box, available to any Wild customer buying their deodorant.',
	wildCaseApproachItemFour:
		'SimplyCook paid a CPA every time a Wild customer was referred to the brand.',
	wildCaseResultsTitle: 'Results',
	plusnetCaseResultsTitle: 'Results',
	wildCaseResultsItemOne:
		'In the first month, Wild drove almost 500 acquisitions for SimplyCook at a conversion rate of 26.08%.',
	wildCaseResultsItemTwo:
		'In return Wild generated five figures worth of commission at a £2.55 EPC.',
	wildCaseResultsItemThree:
		'Besides a new income stream, Wild have also raised their basket completion by offering a gift. This means they sell more deodorant than before GWP.',
	perksCaseLaunchTitle: 'Launch your own brand partnerships with Tyviso',
	perksCaseLaunchDesc:
		"Earn revenue and your customer's hearts by contacting us today. With a quick setup, getting started is a no-brainer.",
	jewelleryCaseTitle: 'Jewellery Case',
	jewelleryCaseDescription:
		'Our Brand Partners Recommend Your Brand To Their Customers',
	foundersFirstNote: `<strong>Competition in digital advertising has never been so high and it's becoming harder and harder for marketers to get valuable and profitable traffic</strong>`,
	foundersSecondNote:
		'Yup - the cost of acquiring customers is increasingly expensive and challenging to track correctly (thanks, Facebook & Apple!) due to circumstances out of marketers control.',
	foundersThirdNote: 'Most e-commerce brands have two challenges with growth:',
	foundersFirstListNote: '1. Getting people to make their first purchase',
	foundersSecondListNote:
		'2. Making enough return on ad spend to justify continued investment',
	foundersFourthNote:
		"<strong>That's why we built Tyviso</strong> - to enable all e-commerce stores to form effective brand partnerships that have a guaranteed return on investment and industry-leading conversion rates.",
	foundersFifthNote:
		'There is no overlap between Tyviso activity and the other acquisition channels, which means all of the new customers that we deliver are entirely incremental to your existing acquisition strategy. Best still, you only pay for what you get. This means Tyviso can run in the background stress-free.',
	foundersSixthNote: "I'd love for you to give it a try.",
	foundersNoteTitle: 'A note from us',
	foundersNoteName: 'Pierre Cozzani',
	foundersNotePosition: 'Founder & CEO',
  findUsOnNetwork: 'Find us on your network',
	ourPartnersTitle: 'Our Partners',
	joinUsNews: 'Join us in the news',
	ourPartnersSubtitle:
		'You can work with Tyviso even quicker through any one of our global affiliate network partnerships. Spot yours below.',
	ourPartnersListTitle: 'Spot your <strong>platform</strong>',
	ourPartnersDescription:
		'Our Brand Partners Recommend Your Brand To Their Customers',
	ourStoryTitle: 'Our Story',
	ourStoryDescription:
		'Our Brand Partners Recommend Your Brand To Their Customers',
	ourStoryText1:
		'Tyviso began in 2021 on a Saturday night. Specifically, right after a tsunami of insert leaflets fell on the floor upon opening a case of wine.',
	ourStoryText2:
		'The ads in question were somewhat relevant, but Pinot Grigio was the real winner of our attention.',
	ourStoryText3:
		'But what if these ads could be more targeted and served at a better time when you are buying the wine instead of drinking it we thought.',
	ourStoryText4: 'Hazily headed the next morning, Tyviso was born.',
	ourStoryText5:
		"After a 10 year spell at advertising behemoth Yahoo! working with some of the world's biggest advertisers we knew first hand that very little is in the favour of the eCommerce store. The cost of digital advertising is getting increasingly more expensive, less trackable and even more difficult to be consistent at scale.",
	ourStoryText6:
		'We also knew we had to change the game to the side of the brand, rather than the publisher.',
	ourStoryText7:
		'In times of uncertain market factors, there’s, even more, a need for brands to stick together. By creating a platform to facilitate working together, brands can pay it forward and grow in unison.',
	ourStoryText8:
		'After presenting the idea of a solution that checked all these boxes and asking whether retailers would use it, we find ourselves where we are today.',
	pressLinksTitle: 'Press Link',
	pressLinkConnect:
		'Publisher Spotlight Tyviso | Tradedoubler – Connect and Grow',
	pressLinkAwin:
		'Awin partners with Tyviso to support clients with the creation of customer reward programmes',
	pressLinkAwinTalks: 'An Introduction to Brand Partnerships | Awin Talks',
	pressLinkPartnerships: 'Brand Partnerships Wrapped: 2022',
	pressLinkWebgains: 'Publisher Spotlight: Tyviso & Webgains',
	pressLinksSpotlight: 'Publisher Spotlight: Tyviso & Tradedoubler',
	pressLinksWebgains: 'Play It Forward: Tyviso & Webgains',
	pressLinksRecipeForSuccess:
		'Awin: How Wild harnessed the power brand-to-brand partnerships',
	pressLinkAwinGwp:
		'Awin launches strategic partnership with Tyviso for Gift With Purchase',
	successStoriesTitle: 'Success Stories',
	successStoriesDescription:
		'Our Brand Partners Recommend Your Brand To Their Customers',
	adSpecsTitle: 'Ad Specs',
	adSpecsDescription: 'Description',
	adSpecsItemOneTitle: 'Logo',
	adSpecsItemOneDescription:
		'A copy of your brand logo, placed on top of the creative.',
	adSpecsItemOneRequirements: '400x200',
	adSpecsItemTwoTitle: 'Creative',
	adSpecsItemTwoDescription:
		'Your image which forms the main basis of your offer slot. Runs as either hero unit or regular unit.',
	adSpecsItemTwoRequirements: 'Hero: 2320 x 644<br>Regular: 746x388',
	adSpecsItemThreeTitle: 'Title',
	adSpecsItemThreeDescription:
		'Your main call to action E.g. “17% Off Bedroom Furniture”.',
	adSpecsItemThreeRequirements: 'Max 50 Characters',
	adSpecsItemFourTitle: 'Description',
	adSpecsItemFourDescription:
		'Sub text underneath your title. E.g. “Make your dream home with these lovely accessories”.',
	adSpecsItemFourRequirements: 'Max 150 Characters',
	adSpecsItemFiveTitle: 'URL',
	adSpecsItemFiveDescription:
		'The link to where the traffic from your offer should land. Please include any tracking requirements.',
	adSpecsItemFiveRequirements: ' ',
	adSpecsItemSixTitle: 'iButton',
	adSpecsItemSixDescription:
		'3 x Bullet Points to sell your product, for shoppers who want more information',
	adSpecsItemSixRequirements: 'Max 50 Characters Each',
	adSpecsItem: 'Item',
	adSpecsRequirements: 'Requirements',
	adSpecsSubtitleOne: 'Example Regular Creative',
	adSpecsSubtitleTwo: 'Example Hero Creative',
	adSpecsSubtitleThree: 'Example iButton',
	blog: 'Blog',
	goLiveQuickly: 'Go live quickly',
	stillNotConvinced: 'Still not convinced?',
	adPreviewTitle: 'Preview your ad',
	adPreviewDescription:
		'Edit your offer details below, so you can see how it would look on our page',
	saveScreenshot: 'Save as a screenshot',
	trending: 'Trending',
  testimonialTitle: 'But don’t just take our word for it...',
	testimonialCJ:
		'£8bn and rising further European Retail Media Market. CJ are here to support Tyviso globally and are very excited for what’s to come!',
	testimonialAwin:
		"We're proud to work with Tyviso via a strategic partnership for the benefit of all of ours brands",
	testimonialTradedoubler:
		"A super unique concept thats a real game changer for ecommerce brands. We're suggesting this to all of our advertisers",
	testimonialWebgains:
		"Tyviso offers a unique opportunity to brands like nothing we've ever seen before.",
	testimonialPartnerize:
		'For brands looking for an interesting and valuable new partner to add to their mix, they should look into Tyviso',
	testimonialForthglade:
		'The Tyviso team have been great! Set up was really straight forward, any questions that we had were answered quickly and feedback on the design of the widget was actioned straight away. Nothing was too much trouble!',
	testimonialForthgladeName: 'Jessie Cox - Digital Marketing Manager',
	testimonialSimplyCook:
		'Gift With Purchase is an incredible acquisition tool for us. We see a conversition rate in excess of 30% from customers who visit our site after selecting us as a gift on a partner site',
	testimonialSimplyCookName: 'Pippa Bryant - Simply Cook',
	customerFaqOneTitle: 'How do I redeem my voucher?',
	customerFaqOneDescription:
		'To redeem your special offer, first click on the “Claim now” button. This will directly lead you to the website of the online store. A field for entering the voucher code will appear during the ordering process (usually in the shopping cart). We recommend that you copy the code in order to avoid errors during input. Some vouchers will be automatically deducted from the shopping cart total after clicking the link.',
	customerFaqTwoTitle: 'Problems with redemption',
	customerFaqTwoDescription:
		'<ol><li>Did you correctly enter the voucher code?</li><li>Have the redemption conditions of the voucher (e.g. minimum order value) been met?</li><li>Please contact us if the redemption still does not work in spite of this. We will be happy to check the problem in detail and make sure you can use the voucher as soon as possible.</li></ol>',
	customerFaqThreeTitle: 'Field for entering the code not found',
	customerFaqThreeDescription:
		'The voucher code is usually entered directly from within the shopping cart. The appropriate field is usually found beneath the order total. It can have different names and does not necessarily have to be called a “voucher code”. The wording “promotion code” or “discount code” may be used here. For some online stores you must first register or log into your customer account and go through the ordering process until the final step. The field in which you enter the code appears just before you send your order.',
	customerFaqFourTitle:
		'Is redemption possible after already placing an order?',
	customerFaqFourDescription:
		'Redeeming the vouchers we provide always needs a new order. Usually you cannot credit the voucher against an order you have already placed.<br /><br />Should you have problems with the redemption process, please contact the store where you ordered. We act as the intermediary and so we cannot do the subsequent redemption for you, as we do not have access to your customer account with the store for data protection reasons.',
	faqFeedbackTitle: 'Did you find this message useful?',
	faqFeedbackYes: 'Yes',
	faqFeedbackNo: 'No',
	faqFeedbackGiven: 'Thanks for your feedback!',
	faqContactTitle: 'Problem claiming your offer?',
	faqContactCta: 'Contact us here',
	tyvisoStudioGiftHostDescription:
		'See examples of Gift With Purchase in action by selecting the brand logos.',
	tyvisoStudioHostDescription:
		'See examples of reward pages from brands by selecting the brand logos.',
	tyvisoStudioSupplierDescription:
		'See what other Brands are promoting now by selecting the brand logos.',
	tyvisoStudioTitle: 'Get it right first time with Tyviso Studio',
	tyvisoStudioDescription:
		'Keeping your brand team happy is essential. Therefore, we offer more levels of customisation on a widget design than Xzibit did to cars during 2004-2007.<br /><br />If you have a design team and want to design your own widget, great! If not, you can use our specialists for free. <br /><br />See below some examples of the Tyviso widget in situ, by clicking the brand logos:',
	brandPartnershipTitleOne:
		'Give your shoppers a gift; receive many yourself.',
	brandPartnershipDescOne:
		'Boost your basket completion rate, delight your shoppers, and earn game-changing commission income.',
	brandPartnershipCtaOne: 'Visit Tyviso GWP',
	brandPartnershipTitleTwo: 'Zero risk, all the rewards',
	brandPartnershipDescTwo:
		'Provide rewards free of charge to your existing customers to keep them coming back for more. Raise your customer lifetime value & profit margins.',
	brandPartnershipCtaTwo: 'View Tyviso Rewards',
	brandPartnershipTitleThree: 'Collaborate, not compete',
	brandPartnershipDescThree:
		'Only work with other brands who aren’t your competitors. Guarantee maximum uplift.',
	brandPartnershipCtaThree: 'Visit Tyviso GWP',
	brandPartnershipTitleFour: 'Monetise without compromise',
	brandPartnershipDescFour:
		'Create a completely new revenue stream on your site. The limit is your imagination, not the sky.',
	brandPartnershipCtaFour: 'View Tyviso Rewards',
	biggestChallangesTitle: 'What are your biggest challenges?',
	biggestChallangesSubTitle: 'We’ll solve them together.',
	biggestChallangesCardOneTitle: 'Aquire new customers<br />(CPA)',
	biggestChallangesCardTwoTitle: 'Raise Lifetime Value<br />(LTV)',
	biggestChallangesCardThreeTitle: 'Monetise Your Site<br />(Revshare)',
	biggestChallangesCardFourTitle: 'Conversion Rate Optimisation<br />(CRO)',
	tyvisoSpecialTitle: 'What Makes Tyviso Special?',
	tyvisoSpecialCardTitleOne: 'Machine Learning Driven',
	tyvisoSpecialCardDescriptioneOne:
		'(Our Algorithm, made by former Yahoo employees does all the work, so you don’t have to)',
	tyvisoSpecialCardTitleTwo: 'Simple Setup',
	tyvisoSpecialCardDescriptioneTwo:
		'(Start seeing instant results with our easy 10 minute setup)',
	tyvisoSpecialCardTitleThree: 'Proven Results',
	tyvisoSpecialCardDescriptioneThree:
		'(Join other brands in double digit uplifts)',
	tyvisoSpecialCardTitleFour: 'Tyviso Studio',
	tyvisoSpecialCardDescriptioneFour:
		'(Our design team does the work for free, you approve)',
	tyvisoSpecialCardTitleFive: 'No hidden fees',
	tyvisoSpecialCardDescriptioneFive:
		'(No setup fees = zero risk)',
	tyvisoSpecialCardTitleSix: 'Performance pricing',
	tyvisoSpecialCardDescriptioneSix: '(Pay nothing, earn on revshare)',
	recommendsTitle: 'Tyviso Recommends',
	recommendsDescription:
		'Drive sales by having a group of all-star brands recommend you to their customers.',
	recommendsSideTitle:
		'Drive sales by having a group of all-star brands recommend you to their customers.',
	recommendsSideDescription:
		'Display an offer of your choice to shoppers whilst they have a credit card in hand and earn a minimum of £6.67 per £1 spent. Guaranteed.',
	rewardsTitle: 'Tyviso Rewards',
	rewardsDescription:
		'Increase customer retention by 5% to boost profit by 25% to 95%',
	rewardsSideTitle:
		'Increase customer retention by 5% to boost profit by 25% to 95%',
	rewardsSideDescription:
		'Host a reward portal and partner with a library of brands to unlock the ultimate triple threat:',
	rewardsSideListOne:
		'Give your existing customers rewards to keep them loyal',
	rewardsSideListTwo:
		'Acquire customers by other portal hosts using you as a reward',
	rewardsSideListThree:
		'Earn revenue via a revshare from any successful reward redemption',
	rewardsCompanies: 'Brands who think Rewards rocks:',
	rewardsEarningLoyalty: 'Start earning loyalty <strong>now</strong>',
	mainFeatures: 'Main Features',
	goals: 'Goals',
	dontTakeOurWord: 'Don’t just take our word for it',
	caseStudyOverviewTitle: 'Case Study',
	caseStudyOverviewSubtitle:
		'Read the stories behind Tyviso’s instant impact on sales, revenue and average order value for leading brands.',
	caseStudyOverviewVitaminsContent:
		'See how Tyviso delivered 23X the normal conversion rate for a popular Vitamin brand.',
	caseStudyOverview: 'Overview:',
	caseStudyOverviewOne:
		'<strong>Launched Tyviso Recommends</strong> that outperformed the programme average significantly over a three month period',
	caseStudyOverviewTwo:
		'<strong>Maintained AOV</strong> during this time period, even after the provision of a discount',
	caseStudyOverviewThree:
		'<strong>Delivered a 89%</strong> new customer rate, ensuring maximum value to the advertiser.',
	caseStudyOverviewCtaText: 'View Case Study',
	raiseCustomerTitle: 'Raise Lifetime Value (LTV)',
	raiseCustomerDescription:
		'You’ve got customers. But, they dont stay for as long as you like.',
	raiseCustomerSideTitle:
		'You’ve got customers. But, they dont stay for as long as you like.',
	raiseCustomerSideDescription:
		'These figures point to one thing: If you haven’t got a watertight retention strategy, you’re missing out.<br /> <br />This is where Tyviso Rewards comes in.',
	raiseCustomerSideListTitle: 'According to the Harvard Business School:',
	raiseCustomerSideListItemOne:
		'1. It’s 5x more expensive to acquire a new customer than to keep one.',
	raiseCustomerSideListItemTwo:
		'2. You can raise revenue by 25%-90% by increasing retention 5%',
	raiseCustomerSideListItemThree:
		'3. The success rate of selling to a customer you already have is 60-70%, while the success rate of selling to a new customer is 5-20%.',
	raiseCustomerFeatures: 'How Tyviso Helps Retention',
	raiseCustomerTitleOne: 'Provide rewards to your customers for being loyal',
	raiseCustomerDescOne:
		"Move over the occasional ‘Here’s 10% off we missed you’ email. There’s a new Sheriff in town.<br /><br />Incentivise your customers to come back to your site and make a purchase whilst viewing the other brands that you’ve secured for their benefit.<br /><br />No needless discounting of your products, just happy shoppers who know you’ve done them a favour.<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	raiseCustomerTitleTwo: 'Display them on all of your touchpoints',
	raiseCustomerDescTwo:
		"Your shoppers will behave completely differently to shoppers on other sites. Maybe you get more mobile traffic, maybe you get more desktop. Maybe even most of it is in-app?<br /><br />Tyviso Rewards can be displayed on and is optimised for all the places where your customers react with you.<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	raiseCustomerTitleThree: 'Bind them with your other communications',
	raiseCustomerDescThree:
		"It’s possible to just have a rewards page and leave it. Or, you can really make it sweat hard for you.<br /><br />Promote your rewards everywhere: Your order confirmation page, CRM e-mails, social media and you site.<br /><br />Even put them on your ads as social proof<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	raiseCustomerTitleFour: 'Your Rewards, Your Choice',
	raiseCustomerDescFour:
		"No one knows your customers better than you. Select from a whole library of rewards partners they’ll love.<br /><br />Mix it up every month and keep giving even more reasons to come back.<br /><br />Did we mention you also make money from providing rewards by taking some of the commission? Check out how to Monetise Your Ecommerce Site (Revshare).<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",

	monetiseEcommerceTitle: 'Monetise Your Site (Revshare)',
	monetiseEcommerceDescription:
		'Acquisition costs have soared. Margins have squeezed and your boss has unrealistic reve- nue expectations for the year.',
	monetiseEcommerceSideTitle:
		'Acquisition costs have soared. Margins have squeezed and your boss has unrealistic reve- nue expectations for the year.',
	monetiseEcommerceSideListItemOne:
		'Enter Tyviso.<br /><br />You can make extra money from your site, without even breaking a sweat',
	monetiseEcommerceFeatures: 'How Tyviso Helps You Monetise',
	monetiseEcommerceTitleOne:
		'Earn money every time a shopper claims a reward or gift',
	monetiseEcommerceDescOne:
		"Each time you refer a brand a new customer, they pay a commission. We split that commission with you. No caps. No limits.<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	monetiseEcommerceTitleTwo: 'Tyviso Technology is Free',
	monetiseEcommerceDescTwo:
		"We don’t charge setup or monthly fees.<br /><br />By sharing commission and not taking any fees off, you keep the maximum amount of the pie.<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	monetiseEcommerceTitleThree: 'We can help you conquer the world',
	monetiseEcommerceDescThree:
		"By the end of 2023, we won’t be able to help you monetise just your UK site, but also many of the major markets (we just need to hire some people that speak Spanish, Portuguese, German and Dutch first).<br /><br />Luckily, America & Australia already speak English. Phew.<br /><br /><a href='https://rtbmedia.hubspotpagebuilder.com/book-a-demo'><strong>Book A Demo</strong></a>",
	monetiseEcommerceStart: 'Start Monetising your site <strong>today</strong>',
};