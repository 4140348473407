import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';

const Layout = ({children, location, i18nMessages}) => {
    return (
        <StaticQuery
            query={
                graphql`
                    query LayoutQuery {
                        site {
                            siteMetadata {
                                languages {
                                    defaultLangKey
                                    langs
                                }      
                            }
                        }
                    }
            `}
            render={
                data => {
                    const url = location.pathname;
                    const {langs, defaultLangKey} = data.site.siteMetadata.languages;
                    const langKey = getCurrentLangKey(langs, defaultLangKey, url);

                    return (
                        <IntlProvider locale={langKey} messages={i18nMessages}>
                            <>
                                {children}
                            </>
                        </IntlProvider>
                    );
                }
            }
        />
    );
};

export default Layout;