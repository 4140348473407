import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import styles from "../styles/CookieConsent.module.css";
import Cta from "./styleguide/Cta";

const CookieConsent = ({fixed}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(!window.localStorage.getItem("cookieConsentAccepted"));
    }, []);

    const onCookieConsentClick = () => {
        window.localStorage.setItem("cookieConsentAccepted", "yes");
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <div className={`${styles.CookieConsent} ${fixed ? styles.fixed : ""}`}>
            <div className={styles.CookieConsentPlaceholder}>
                <div>
                    <h5><FormattedMessage id="cookieConsentTitle" /></h5>
                    <p><FormattedHTMLMessage id="cookieConsentDescription" /></p>
                </div>
                <Cta className={styles.CookieConsentCta} text="cookieConsentAccept" onClick={onCookieConsentClick} />
            </div>
        </div>
    );
};

export default CookieConsent;
