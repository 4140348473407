import { Link } from "gatsby";
import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import styles from "./Cta.module.css";

const Cta = ({link, text, className, onClick}) => {
    if (link) {
        return (
            <Link to={link} className={`${styles.Cta} ${className}`}><FormattedHTMLMessage id={text} /></Link>
        );
    } else if (onClick) {
        return (
            <span className={`${styles.Cta} ${className}`} onClick={onClick}><FormattedHTMLMessage id={text} /></span>
        );
    }

    return (
        <span className={`${styles.Cta} ${className}`}><FormattedMessage id={text} /></span>
    );
};

export default Cta;